import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts"
import { buildBreadcrumb } from "./../utils/buildBreadcrumb"
import image from "./../images/EmmaWorthington.jpg"
import { getMonth } from "./../utils/getMonth"
import { HeroEdit } from "./../components/hero"
import {
  Text,
  FavouriteItem,
  ItemFeature,
  NewsletterSubscription,
} from "../components/slices"
import { EditPod } from "../components/pods"

// Query for the Blog Post content in Prismic
export const query = graphql`
  query EditQuery($uid: String!) {
    allPrismicHomepage {
      edges {
        node {
          data {
            title {
              text
            }
          }
          id
          type
        }
      }
    }
    prismicEdit(uid: { eq: $uid }) {
      data {
        seotitle
        seodescription
        title {
          text
        }
        hero_text_colour
        hero_image {
          url(imgixParams: { q: 90 })
          thumbnails {
            huge {
              url(imgixParams: { q: 90 })
            }
            medium {
              url(imgixParams: { q: 90 })
            }
            mega {
              url(imgixParams: { q: 90 })
            }
            small {
              url(imgixParams: { q: 90 })
            }
            tiny {
              url(imgixParams: { q: 90 })
            }
            large {
              url(imgixParams: { q: 90 })
            }
          }
        }
        published_date
        related_edits {
          __typename
          document {
            ... on PrismicEdit {
              data {
                title {
                  text
                }
                snippet {
                  text
                }
                hero_image {
                  url
                }
                published_date
                category {
                  document {
                    ... on PrismicCategory {
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
              id
              uid
              type
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              data {
                name
                title {
                  text
                }
              }
              id
              uid
              type
            }
          }
        }
        body {
          __typename
          ... on PrismicEditBodyRichText {
            slice_type
            slice_label
            primary {
              text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicEditBodyFavouriteItem {
            slice_type
            slice_label
            primary {
              item {
                __typename
                document {
                  ... on PrismicFavourite {
                    data {
                      title {
                        text
                      }
                      content {
                        html
                      }
                      image {
                        url(imgixParams: { q: 90 })
                      }
                      price
                      retailer
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEditBodyItemFeature {
            slice_type
            primary {
              title1 {
                text
              }
              content {
                html
                text
              }
              image {
                url(imgixParams: { q: 90 })
              }
              product_name {
                text
              }
              price
              merchant
              link {
                url
              }
              button_text
            }
          }
        }
      }
      id
      uid
      type
    }
  }
`

// Sort and display the different slice options
const EditSlices = ({ slices }) => {
  if (!slices) {
    return null
  }
  return slices.map((slice, index) => {
    const response = (() => {
      switch (slice.slice_type) {
        case "rich_text":
          return (
            <div key={index} className="edit-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          )

        case "favourite_item":
          return (
            <div key={index} className="edit-slice-wrapper">
              {<FavouriteItem slice={slice} />}
            </div>
          )

        case "item_feature":
          return (
            <div key={index} className="edit-slice-wrapper">
              {<ItemFeature slice={slice} />}
            </div>
          )

        default:
          return (
            <h1>Slice type {slice.slice_type} is not currently supported</h1>
          )
      }
    })()
    return response
  })
}

export default ({ data }) => {
  // Define the Post content returned from Prismic
  const { allPrismicHomepage, prismicEdit } = data
  const pageData = prismicEdit.data
  const homepageData = allPrismicHomepage.edges[0].node
  const { related_edits, category } = pageData

  const thisBreadcrumb = buildBreadcrumb([
    homepageData,
    category.document,
    prismicEdit,
  ])

  const editDate = new Date(pageData.published_date)
  const editDay = editDate.getDate()
  const editMonth = editDate.getMonth()
  const editYear = editDate.getFullYear()
  const date = `${editDay} ${getMonth(editMonth)} ${editYear}`

  return (
    <Layout>
      <Helmet>
        <title>
          {pageData.seotitle ? pageData.seotitle : pageData.title.text}
        </title>
        <meta name="description" content={pageData.seodescription} />
      </Helmet>
      <HeroEdit data={pageData} breadcrumb={thisBreadcrumb} />
      <div className="edit-detail">
        <div className="[ container -pinched ]">
          <div className="edit-detail__grid">
            <div className="edit-detail__content">
              <h1 className="title">{pageData.title.text}</h1>
              <p className="edit-date">{date}</p>
              <div className="author-info">
                <div className="author-info__image">
                  <img src={image} alt="Emma Worthington" loading="lazy" />
                </div>
                <p className="author-info__text">By Emma Worthington</p>
              </div>
              <EditSlices slices={pageData.body} />
            </div>
            <div className="edit-detail__sidebar">
              <NewsletterSubscription
                className={`newsletter-subscription--sidebar`}
                showImage={true}
              />
              {related_edits.document && (
                <div className="related-edits-holder">
                  <p className="title h3 -center">Related articles</p>
                  <EditPod className="related" data={related_edits.document} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
